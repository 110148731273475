<template>
  <div class="deposit">
    <titles></titles>
    <div class="banner">
      <div class="banner_text">
        <div class="banner_text_box">
          <h3>400+城市直营社保账户托管服务</h3>
          <p>邦芒社保 深耕企业社保领域{{ $config.bmYears }}年</p>
          <p>一站式社保服务解决方案</p>
          <p>本地/异地员工社保服务 * 托管 *薪资*个税*工资条</p>
          <div class="banner_btn"><a href="https://affim.baidu.com/unique_28438292/chat?siteId=13950180&userId=28438292&siteToken=22dd0d31e1f7a80559410d71206df7db" target="_blank">立即咨询</a></div>
        </div>
      </div>
      <img src="@/assets/social_banner.jpg" />
    </div>
    <div class="deposit_one">
      <div class="all_box">
        <div class="deposit_one_title">
          <textTitle
            :cnTitle="txt.oneCnTitle"
            :enTitle="txt.oneEnTitle"
          ></textTitle>
        </div>

        <div class="deposit_one_list">
          <div class="deposit_one_item">
            <div class="deposit_item_icon">
              <img src="@/assets/serv_icon1.png" />
            </div>
            <div class="deposit_item_text">
              <h4>员工社保服务</h4>
              <ul>
                <li>账户增减员</li>
                <!-- <li>补缴</li> -->
                <li>年度基数调整</li>
                <li>异地员工缴纳</li>
              </ul>
            </div>
          </div>
          <div class="deposit_one_item">
            <div class="deposit_item_icon">
              <img src="@/assets/serv_icon2.png" />
            </div>
            <div class="deposit_item_text">
              <h4>社保账户托管</h4>
              <ul>
                <li>社保账户开立</li>
                <li>自有账户托管</li>
                <li>用退工备案</li>
              </ul>
            </div>
          </div>
          <div class="deposit_one_item">
            <div class="deposit_item_icon">
              <img src="@/assets/serv_icon3.png" />
            </div>
            <div class="deposit_item_text">
              <h4>其他系统服务</h4>
              <ul>
                <li>电子账单服务</li>
                <li>在线申报服务</li>
                <li>实时政策服务</li>
                <li>转入转出服务</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="henfu_bo">
        <henfu></henfu>
      </div>
    </div>
    <div class="deposit_two">
      <div class="all_box">
        <div class="deposit_two_title">
          <textTitle
            :cnTitle="txt.twoCnTitle"
            :enTitle="txt.twoEnTitle"
          ></textTitle>
        </div>
        <div class="deposit_two_box">
          <div class="deposit_two_list_box">
            <div class="deposit_two_list">
              <div class="deposit_two_item">
                <span>01</span>社保入税 基数合规风险
              </div>
              <div class="deposit_two_item">
                <span>03</span>异地参保 人手不足
              </div>
            </div>
            <div class="deposit_two_list">
              <div class="deposit_two_item">
                <span>02</span>错缴漏缴 问题频发
              </div>
              <div class="deposit_two_item">
                <span>04</span>社保政策 变化频繁
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="deposit_three">
      <div class="all_box">
        <div class="deposit_three_title">
          <textTitle
            :cnTitle="txt.threeCnTitle"
            :enTitle="txt.threeEnTitle"
          ></textTitle>
        </div>
        <div class="deposit_three_body">
          <div class="deposit_three_list">
            <div class="deposit_three_item left">
              <h4>{{ $config.bmYears }}年丰富服务经验</h4>
              <p>海量成功案例，更成熟专业</p>
            </div>
            <div class="deposit_three_item right">
              <h4>全国400+网点服务能力</h4>
              <p>全国化托管服务，便捷高效</p>
            </div>
          </div>
          <div class="deposit_three_list">
            <div class="deposit_three_item left">
              <h4>在线服务SaaS平台</h4>
              <p>在线实时申报、电子代办、保密可查</p>
            </div>
            <div class="deposit_three_item right">
              <h4>专业合规资质</h4>
              <p>正规人力资源许可，可靠放心</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <serveFlow :dataList="serveList"></serveFlow>
    <check :type="2" @gotoContact="gotoContact($event)"></check>
    <foots @navChange = "navChange"></foots>
  </div>
</template>
  <script>
//图片
import sb01 from "@/assets/sb01.png";
import sb02 from "@/assets/sb02.png";
import sb03 from "@/assets/sb03.png";
import sb04 from "@/assets/sb04.png";
import sb05 from "@/assets/sb05.png";
import sb06 from "@/assets/sb06.png";

import serveFlow from "@/components/serveFlow.vue";
import henfu from "@/components/henfu.vue";
import textTitle from "@/components/textTitle.vue";
import titles from "@/components/titles.vue";
import foots from "@/components/foots.vue";
import check from "@/components/check.vue";
export default {
  name: "Social",
  components: {
    titles,
    foots,
    textTitle,
    henfu,
    serveFlow,
    check,
  },
  data() {
    return {
      serveList: [
        {
          title: "在线咨询",
          img: sb01,
        },
        {
          title: "制定专属方案",
          img: sb02,
        },
        {
          title: "签订服务协议",
          img: sb03,
        },
        {
          title: "提供参保信息",
          img: sb04,
        },
        {
          title: "支付结算",
          img: sb05,
        },
        {
          title: "企业/员工参保享受结果确认",
          img: sb06,
        },
      ],
      txt: {
        oneCnTitle: "我们能提供什么服务",
        oneEnTitle: "Provision of services",
        twoCnTitle: "企业是否面临以下问题",
        twoEnTitle: "Facing problems",
        threeCnTitle: "选邦芒社保，有效为您解决社保问题",
        threeEnTitle: "Bangmang Social Security",
      },
    };
  },
  mounted(){
    window.scrollTo(0, 0);
  },
  methods: {
    //跳转到contact 并查询
    gotoContact(e) {
      this.$router.push({
        path: "/contact",
        query: {
          sCode: e.sCode,
          qCode: e.qCode,
          compname: e.compname,
        },
      });
    },
    navChange(){
      window.scrollTo(0, 0);
    }
  },
};
</script>
  <style lang="scss" scoped>
.banner_btn {
  a{
    color: #fff;
    display: block;
    text-decoration: none;
  }
  font-size: 16px;
  width: 140px;
  height: 40px;
  border-radius: 15px;
  text-align: center;
  color: #fff;
  background: #d70035;
  line-height: 40px;
  margin-top: 50px;
}
.banner {
  position: relative;
  .banner_text {
    position: absolute;
    width: 100%;
    text-align: left;
    top: 50%;
    margin-top: -100px;
  }
  .banner_text_box {
    width: 80%;
    margin: 0 auto;
    h3 {
      font-size: 55px;
      color: #555555;
      margin: 0;
      margin-bottom: 32px;
    }
    p {
      font-size: 24px;
      color: #7e7d7b;
      margin: 0;
      margin: 12px 0;
      span {
        color: #d70035;
      }
    }
  }
}

.deposit_three_title {
  padding: 75px 0 120px;
}
.deposit_three {
  background: #f2f5fa;
  padding-bottom: 90px;
}
.deposit_three_body {
  background: url(../../assets/deposit_three_bg.png) center center no-repeat;
  background-size: 394px 394px;
  height: 394px;
}
.deposit_three_item.left {
  text-align: right;
}
.deposit_three_item.right {
  text-align: left;
}
.deposit_three_item {
  width: 300px;
}
.deposit_three_list {
  height: 197px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  h4 {
    font-size: 24px;
    color: #282828;
    margin: 0;
    margin-bottom: 20px;
    margin-top: 50px;
  }
  p {
    font-size: 16px;
    color: #656565;
    margin: 0;
  }
}
.deposit_two {
  padding-bottom: 150px;
}
.deposit_two_item {
  color: #696969;
  font-size: 24px;
  span {
    font-size: 20px;
    color: #fff;
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    line-height: 40px;
    background: #caa05b;
    margin-right: 17px;
  }
}
.deposit_two_list_box {
  padding-top: 30px;
}
.deposit_two_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 156px;
  line-height: 156px;
}
.deposit_two_title {
  padding: 90px 0 110px;
}
.deposit_two_box {
  background: url(../../assets/deposit_two_bg.png) center center no-repeat;
  background-size: 100% 100%;
  height: 353px;
  padding: 0 100px;
}
.deposit_one {
  position: relative;
  height: 780px;
  padding-top: 100px;
}
.deposit_one_title {
  position: relative;
  height: 100px;
  h3 {
    font-size: 60px;
    color: #2b343f;
    opacity: 0.08;
    margin: 0;
    position: absolute;
    width: 100%;
    z-index: 0;
    text-transform: uppercase;
  }
  h4 {
    font-size: 42px;
    color: #3d3d3d;
    margin: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 30px;
  }
}
.henfu_bo {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.deposit_one_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 140px;
  position: relative;
  z-index: 2;
  .deposit_one_item {
    width: 32%;
    background: #fff;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
    .deposit_item_icon {
      margin-top: -60px;
    }
    .deposit_item_text {
      h4 {
        font-size: 20px;
        color: #696969;
        font-weight: bold;
      }
      ul {
        text-align: left;
        width: 31%;
        margin: 0 auto;
        li {
          margin: 25px 0;
          color: red;
        }
      }
    }
  }
}
</style>